.container-text {
    margin:0;   
    border-right: 1px solid white;
    padding-right: 1rem;
}
.container-text-about {
    margin-top:3rem;
    max-width: 600px;
}

.image-section {
    flex: 0.5;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.profile-image {
    width: 30vw;
    height: 45vh;
    max-width: 400px; 
    max-height: 400px;
    border-radius: 50%;
    object-fit: cover;
    margin-left:10rem;
    margin-right: 9.45rem;
    margin-top: 3rem;
    
}

.container-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top:1rem;

}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}   
.social-links {
    display: flex;
    gap: 0.5rem;
}

.social-icon {
    width: 80px;
    height: 80px;
    margin-top: 1rem;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.github {
    background-image: url('../../assets/icons8-github.png'); /* Replace with actual GitHub icon URL */
}

.linkedin {
    background-image: url('../../assets/icons8-linkedin.png'); /* Replace with actual LinkedIn icon URL */
}



.scroll-indicator {
    position: absolute;
    left: 50%;
    transform: translateX(-50%), translateY(-100%);
    text-align: center;
}

@media screen and (max-width: 768px) {
    .container-text {
        border-right: none;
        padding-right: 0;
    }
    .profile-image {
        margin-left: 0;
        margin-right: 0;
    }
    .container-icons {
        margin-top: 0;
    }
    .social-icon {
        width: 60px;
        height: 60px;
    }
    .button {
        margin-left: 0;
    }
    
}