.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 5rem; 
  flex-wrap: wrap;
}
.link {
  font-family: 'Poppins';
  font-size: 2rem;
  color:#fff
}

a {
  text-decoration: none;
  color:inherit
}
.link:hover {
  color: #7E9BFF;
}

.container-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #101214;
  color: #fff; 
  font-family: 'Poppins';
  gap:1rem;
}

.container-subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #101214; 
  color: #fff; 
  font-family: 'Poppins';
  gap:1rem;
}

.container-text {
  flex: 1;
  display: flex;
  margin-top:3rem;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.6;
}
.title {
  font-size: 4.5rem; 
  font-weight: bold; 
  margin: 0;
}
.subtitle {
  font-size: 1.75rem; 
  font-weight: 600; 
  margin: 0;
}

.button {
  background-color: #7E9BFF;
  color:#fff;
  padding:10px;
  border: none;
  border-radius: 24px;  
  width: 255px;
  margin:1rem;
  margin-left:30%;
  font-size: 1rem;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.button:hover {
  cursor: pointer;
}
.description {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
}

.container-title::after {
  content: "";
  flex-grow: 1;
  height: 1px; 
  background-color: #fff; 
  margin: 0;
}
.container-title::before {
  content: "";
  width: 4rem;
  height: 1px;
  background-color: #fff;
  margin: 0;
}
.container-title.v2::before {
  content: "";
  width: 38vw;
  height: 1px;
  background-color: #fff;
  margin: 0;
}
.container-subtitle::after {
  content: "";
  flex-grow: 1;
  height: 0px; 
  background-color: #fff; 
  margin: 0;
}

.container-subtitle::before {
  content: "";
  width: 4rem;
  height: 0px; 
  background-color: #fff; /* White line color */
  margin: 0;
}

@media screen and (max-width: 768px) {
  .container {
    margin-left: 1rem;
  }
  .container-text {
    margin-top: 0;
  }
  .button {
    margin-left: 0;
  }
}