.project-main {
    width: 100%;
    object-fit: cover;
}

.container-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding:40px
}
.container-details:nth-child(even) {
    flex-direction: row-reverse;
}
.project-image {
    width: 100%;
    max-width: 600px;
    object-fit: contain;
    height: auto;   
}
.project-image:nth-child(even) {
    order: 2;
}

.project-description {
    flex: 1;
    align-content: center;
    font-size: 1.25rem;
}

@media (max-width: 768px) {
    .container-details {
        flex-direction: column;
        gap: 2rem;
    }
    .project-image {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    .project-image:nth-child(even) {
        order: 1;
    }
}