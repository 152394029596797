/* card.css */

.card {
    border: 1px solid #fff;
    color: #fff; 
    border-radius: 12px; 
    overflow: hidden; 
    max-width: 600px;
    max-height: 600px;
    overflow: hidden;
    margin: 0 auto; 
    padding: 2rem; 
    font-family: 'Poppins';
  }
    
  .card-title {
    font-size: 4rem; 
    font-weight: 600;
    text-align: center;
    margin:0px;
  }
  
  .card img {
    width: 100%;
    height: 300px; 
  }
  
  .container-tag {
    display: flex; 
    justify-content: start; 
    margin-top: 15px; 
    flex-wrap: wrap;
    gap: 2px
  }
  
  .tag {
    color: #fff; 
    padding-left:14px;
    padding-right:14px;
    border-radius: 24px; 
    font-size: 0.9rem; 
    border: 1px solid #fff;
    font-weight: 600; 
    text-transform: uppercase;

  }